<script setup lang="ts">

</script>

<template>
  <div class="text111">
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
    <p>ThemeComponent</p>
  </div>
</template>

<style scoped>

.text111 {
  background-color: var(--bg-color);
  color: var(--text-color);
}

</style>
