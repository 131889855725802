<script setup lang="ts">

</script>

<template>
<div>
  消息提示组建
</div>
</template>

<style scoped>

</style>
