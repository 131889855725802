<script setup lang="ts">

</script>

<template>
   <h1>Dynamic---2</h1>
</template>

<style scoped>

</style>
